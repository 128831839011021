export default {
  inputPlaceholder: "Menu list name",
  menuOverview: "Menu Overview",
  menuList: "Menu List",
  menu: "Menu",
  menuItem: "Menu Items",
  menuImage: "Menu Image",
  menuItemText:
    "Upload your food menu by items for easy discovery and menu management in the future.",
  menuImageText:
    "Upload the menu you already have to launch your store faster.",
  total: "Total",
  inactive: "Inactive",
  createMenuItems: "Create Menu Item",
  inputMenuItem: "Menu Item Name",
  deleteMenuItems: "Delete All Menu Items",
  editAMenu: "Edit a menu",
  tableHeader: {
    menuId: "No.",
    photo: "Photo",
    image: "Image",
    name: "Name",
    description: "Description",
    price: "Price",
    subMenu: "Sub Menu",
    category: "Category",
    action: "Action",
  },
  showOnlyItemsWithoutImage: "Show only items without images",
  selectMain: "Main: {0}",
  selectOptional: "Optional: {0}",
  removeImage: "Remove Image",
  uploadImage: "Upload Image",
  areYouSureWantToRemoveThisImage:
    "Are you sure you want to remove this image?",
  remove: "Remove",
};
