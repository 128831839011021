export default {
  emailRequired: "E-mail is required",
  emailValid: "E-mail must be valid",
  passwordRequired: "Password is required",
  passwordValid: "Password must be less than 10 characters",
  passwordNotMatch: "The password confirmation doesn’t match.",
  requiredText: "This is required",
  emailNotCorrectFormat:
    "Please enter in the correct email format — name@example.com",
  minimumValueIs: "Minimum value is {0}",
  cannotInputQuantityLessThan: "Can't input quantity less than {0}",
};
