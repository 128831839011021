export default {
  emailRequired: "ต้องกรอกอีเมล",
  emailValid: "ต้องกรอกอีเมลในรูปแบบที่ถูก",
  passwordRequired: "ต้องกรอกรหัสผ่าน",
  passwordValid: "รหัสผ่านต้องมากกว่า 10 ตัวอักษร",
  passwordNotMatch: "รหัสผ่านไม่ตรงกัน",
  requiredText: "จำเป็นต้องกรอก",
  emailNotCorrectFormat: "กรุณากรอกรูปแบบอีเมลที่ถูกต้อง - name@example.com",
  minimumValueIs: "ค่าต่ำสุดคือ {0}",
  cannotInputQuantityLessThan: "ไม่สามารถใส่จำนวนน้อยกว่า {0}",
};
