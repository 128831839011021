export const analyticsRouter = [
  {
    path: "/analytics/performanceReview",
    name: "analytics-performance-review",
    component: () =>
      import("../pages/analytics/performance-review/PerformanceReview.vue"),
    meta: {
      text: "$vuetify.layout.navigationSidebar.performanceReview",
      parent: {
        name: "$vuetify.layout.navigationSidebar.analytics",
        disabled: false,
        path: "/analytics",
        exact: true,
      },
    },
  },
  {
    path: "/analytics/ranking",
    name: "analytics-ranking",
    component: () => import("../pages/analytics/ranking/Ranking.vue"),
    meta: {
      text: "$vuetify.layout.navigationSidebar.ranking",
      parent: {
        name: "$vuetify.layout.navigationSidebar.analytics",
        disabled: false,
        exact: true,
      },
    },
  },
];
