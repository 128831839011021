export default {
  inputPlaceholder: "Menu list name",
  menuOverview: "ภาพรวมเมนู",
  menuList: "Menu List",
  menu: "Menu",
  menuItem: "รายการเมนู",
  menuImage: "เมนูภาพ",
  menuItemText:
    "อัปโหลดเมนูอาหารของคุณตามรายการเพื่อการค้นหาและการจัดการที่ง่ายในอนาคต.",
  menuImageText: "อัปโหลดเมนูที่คุณมีอยู่แล้วเพื่อความรวดเร็ว.",
  total: "ทั้งหมด",
  inactive: "Inactive",
  createMenuItems: "Create Menu Item",
  inputMenuItem: "ชื่อรายการเมนู",
  deleteMenuItems: "Delete All Menu Items",
  editAMenu: "Edit a menu",
  tableHeader: {
    menuId: "หมายเลข",
    photo: "Photo",
    image: "รูปภาพ",
    name: "ชื่อเมนู",
    description: "คำอธิบาย",
    price: "ราคา",
    subMenu: "Sub Menu",
    category: "ประเภท",
    action: "ตัวเลือก",
  },
  showOnlyItemsWithoutImage: "แสดงเฉพาะเมนูที่ไม่มีรูปภาพ",
  selectMain: "ภาษาหลัก: {0}",
  selectOptional: "ภาษารอง: {0}",
  removeImage: "ลบรูปภาพ",
  uploadImage: "อัปโหลดรูปภาพ",
  areYouSureWantToRemoveThisImage: "คุณแน่ใจหรือไม่ว่าต้องการลบรูปภาพนี้?",
  remove: "ลบรูปภาพ",
};
