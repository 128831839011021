// import {isProduction} from "@/utils/environment.util";
import { getStorageSwitcher } from "@/utils/storage.util";
import { LAYOUT_HEADER, THEME_COLORS } from "./theme.config";
export { LAYOUT_HEADER, THEME_COLORS };

export const APP_BASE_API_URL =
  getStorageSwitcher()?.APP_BASE_API_URL ||
  "https://hh-engineering.my.id/api/partner/v1/";

export const APP_BASE_API_RESTAURANT_URL =
  getStorageSwitcher()?.APP_BASE_API_RESTAURANT_URL ||
  "https://hh-engineering.my.id/api/v5/restaurants/";

export const APP_BLOGS_API_URL =
  import.meta.env.APP_BLOGS_API_URL ||
  "https://blog.hungryhub.com/wp-json/wp/v2/";

export const BASE_WEB_APP_URL =
  import.meta.env.APP_BASE_WEB_APP_URL || window.location.origin;

export const HYPERLINK_RESTAURANT =
  import.meta.env.APP_HYPERLINK_RESTAURANT ||
  "https://madison.hungryhub.com/en/restaurants/";

export const HYPERLINK =
  import.meta.env.APP_HYPERLINK ||
  "https://hungryhub-build-staging.netlify.app";

export const APP_RANKING_ANALYTICS_URL =
  import.meta.env.APP_RANKING_ANALYTICS_URL ||
  "https://hungryhub-build-staging.netlify.app";

export const APP_CHART_URL =
  import.meta.env.APP_CHART_URL ||
  "https://charts.mongodb.com/charts-project-0-cxihk";

export const APP_HH_MENU_URL =
  import.meta.env.APP_HH_MENU_URL || "https://menu.hh-engineering.my.id/";

export const APP_HH_MENU_API_URL =
  getStorageSwitcher()?.APP_HH_MENU_API_URL ||
  "https://menu.hh-engineering.my.id/api/v1/";

export const APP_IMAGE_PROXY =
  import.meta.env.APP_IMAGE_PROXY || "https://imgproxy2.hh-engineering.my.id/";

export const APP_MENU_PAGE_URL =
  import.meta.env.APP_MENU_PAGE_URL || "https://hh-pegasus.netlify.app/";

export const APP_CHROME_SERVICE =
  import.meta.env.APP_CHROME_SERVICE || "https://chrome.hungryhub.com/";

export const APP_GOOGLE_API_KEY = import.meta.env.APP_GOOGLE_API_KEY;

export const APP_GOOGLE_MAP_ID = import.meta.env.APP_GOOGLE_MAP_ID;

export const SYSTEM_LOCALES = [
  {
    name: "th",
    flag: "$iconLanguageTha",
  },
  {
    name: "en",
    flag: "$iconLanguageEng",
  },
  {
    name: "cn",
    flag: "$iconLanguageCn",
  },
];

export const SETTINGS = {
  locale:
    window.localStorage.getItem("locale") ||
    import.meta.env.APP_DEFAULT_LOCALE ||
    "en",
};

export const SYSTEM_CONFIG = {
  version: import.meta.env.APP_VERSION,
  storage: "localStorage",
  prefix: "hrhub",
  ttl: 7 * 24 * 60,
  whiteListsUrl: [
    "/login",
    "/auto-login",
    "/global/helps",
    "/register/verify",
    "/register/success",
    "/register/new-partner",
  ],
  deniedListsUrl: [
    "/login",
    "/register/verify",
    "/register/success",
    "/register/new-partner",
  ],
};

export const PACKAGE_TYPES = [
  {
    label: "All You Can Eat (AYCE)",
    value: "ayce",
    id: 1,
    color: "yellow",
  },
  {
    label: "Party Pack (PP)",
    value: "pp",
    id: 2,
    color: "green",
  },
  {
    label: "Xperience (XP)",
    value: "xp",
    id: 3,
    color: "blue",
  },
  {
    label: "Hungry@Home (HAH)",
    value: "hah",
    id: 4,
    color: "pink",
  },
];
