export default {
  header: {
    logout: "Logout",
    language: "Language",
    searchForm: {
      inputPlaceholder: "Booking ID",
      button: "Search",
    },
    notifications: {
      NewForYou: "New for you",
      MarkAllRead: "Mark All Read",
      PreviousNotifications: "Previous Notifications",
      updateHeadline: "Update headline",
      youHave: "You have",
      newPendingBooking: "new Pending Bookings",
      newPendingConfirmationBooking: "new Pending Confirmation Bookings",
      seemore: "See More",
      newCustonerReview: "new Customer Reviews",
      booking: "Booking",
      wroteAReview: "wrote a review",
    },
  },
  navigationSidebar: {
    dashboard: "Dashboard",
    booking: "Booking",
    bookingHistory: "Booking History",
    voucher: "Voucher",
    voucherList: "Voucher List",
    voucherHistory: "Voucher History",
    createVoucher: "Create Voucher",
    editVoucher: "Edit Voucher",
    package: "Package",
    billing: "Billing",
    createPackage: "Create Package",
    editPackage: "Edit Package",
    reviewManangement: "Review Management",
    marketing: "Marketing",
    accountManagement: "Account Management",
    profile: "Profile",
    store: "Store",
    user: "User & Access Right",
    help: "Help",
    menu: "Menu",
    menuOverview: "Menu Overview",
    menuItems: "Menu Items",
    createMenuItem: "Create Menu Item",
    editProfile: "Edit Profile",
    editMenuItem: "Edit Menu Item",
    analytics: "Analytics",
    performanceReview: "Performance Review",
    ranking: "Ranking",
    allotment: "Allotment Management",
    createAllotment: "Create Allotment",
    editAllotment: "Edit Allotment",
    createBooking: "Create Booking",
    editBooking: "Edit Booking",
    editHistory: "Edit History",
    availableSeats: "Available Seats",
    marketingDetails: "Marketing Details",
    pageNotFound: "Page Not Found",
    exportHistory: "Export History",
    report: "Report",
  },
  registerLayout: {
    register: "Register",
  },
};
