export const registerRouter = [
  {
    path: "/register/verify",
    name: "register-verify",
    component: () =>
      import("../pages/register/components/verified-page/VerifiedPage.vue"),
    meta: {
      text: "$vuetify.layout.registerLayout.register",
    },
  },
  {
    path: "/register",
    component: () => import("@/layouts/registration/RegistrationLayout.vue"),
    meta: {
      text: "$vuetify.layout.registerLayout.register",
    },
    children: [
      {
        path: "",
        name: "register",
        component: () => import("../pages/register/RegisterPage.vue"),
        meta: {
          text: "$vuetify.layout.registerLayout.register",
        },
      },
      {
        path: "new-partner",
        name: "register-new-partner",
        component: () =>
          import("../pages/register/new-partner/RegisterNewPartner.vue"),
      },
      {
        path: "success",
        name: "register-success",
        component: () =>
          import(
            "../pages/register/components/successful-page/SuccessfulPage.vue"
          ),
      },
      {
        path: "setup",
        name: "register-type",
        component: () => import("../pages/register/store-setup/StoreSetup.vue"),
      },
    ],
  },
];
