const DefaultLayout = () => import("@/layouts/default/DefaultLayout.vue");

export const menuV2Router = [
  {
    path: "/menus-v2",
    component: DefaultLayout,
    children: [
      {
        path: "",
        name: "menu-v2",
        component: () => import("../pages/menu-v2/MenuList.vue"),
        meta: {
          text: "$vuetify.layout.navigationSidebar.menuItems",
          parent: {
            name: "$vuetify.layout.navigationSidebar.menu",
            path: "/menus-v2",
            disabled: false,
          },
        },
      },
    ],
  },
];
