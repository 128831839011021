export default {
  title: "Day & Time To Receive Bookings",
  youCannotReduceTheSeatsBelow10Seats:
    "You cannot reduce the seats below 10 seats, please contact us via support and we will reply you within 1-2 working days.",
  action: "action",
  adults: "Adults",
  allotmentCapacityUtilization: "Allotment Capacity Utilization",
  allotmentCapacityUtilizationComparison:
    "Allotment Capacity Utilization Comparison",
  allotment: "Allotment",
  allotmentDetails: "Allotment details",
  applyToAllDay: "Apply to All Day",
  availableAllotment: "Available Allotment",
  availableSeats: "Available Seats",
  avail: "Avail",
  block: "Block",
  blocked: "Blocked",
  bookedAllotment: "Booked Allotment",
  bookedSeats: "Booked Seats",
  bookingID: "Booking ID",
  cancel: "Cancel",
  close: "Close",
  createAllotment: "Create Allotment",
  customerName: "Customer Name",
  fullday: "Full Day",
  daily: "Daily",
  date: "Date",
  dayTimeToReceiveBookings: "Day & Time to receive bookings",
  dineIn: "Dine in",
  due: "Due",
  edit: "Edit",
  editAllotment: "Edit Allotment",
  editPackage: "Edit Package",
  endDate: "End Date",
  endTime: "End Time",
  friday: "Friday",
  lastMonth: "Last Month",
  letOpenUpSomeSeats: "Let’s open up some seats!",
  monday: "Monday",
  monthly: "Monthly",
  moreDetails: "More Details",
  other: "Other",
  packageDetails: "Package Details",
  packageName: "Package Name",
  packageType: "Package Type",
  paidAmount: "Paid Amount",
  partySize: "Party size",
  price: "Price",
  saturday: "Saturday",
  seatAvailable: "Seat Available",
  seats: "Seats",
  specialRequest: "Special Request",
  startDate: "Start date",
  startTime: "Start Time",
  status: "status",
  submit: "Submit",
  sunday: "Sunday",
  support: "Support",
  thisMonth: "This Month",
  thursday: "Thursday",
  time: "time",
  today: "Today",
  total: "Total",
  totalAllotment: "Total Allotment",
  totalAllotmentSeat: "Total Allotment",
  totalMin: "Total (Min {0})",
  totalSeat: "Total Seat",
  tuesday: "Tuesday",
  unblock: "Unblock",
  wednesday: "Wednesday",
  successfullyCreatedAllotment: "You have successfully created the allotment",
  pleaseSelect: "Please Select",
  noLongerAvailable: "No longer available",
  firstBookingTime: "First Booking time",
  lastBookingTime: "Last Booking time",
  textAllotment:
    "You cannot reduce the seat to below {0}. Please contact Line ID: @HHforBusiness and we will reply within 1-2 business days.",
  blockAllotment: "Block Allotment",
  privateFunction: "Private Function",
  restaurantIsClosed: "Restaurant is closed",
  LowFoodStock: "Low food stock",
  fullyBooked: "Fully Booked",
  Others: "Others",
  booked: "Booked",
  reasonToBlock: "Reason to Block",
  allotmentText:
    "If you are trying to block future dates, please contact us via support and we will reply you within 1-2 working days.",
  hours: "Hours",
  minutes: "Minutes",
  quantity: "Quantity",
  quantityMin: "Quantity (Min {0})",
  seatsAdjusted: "Seats adjusted",
  adjustSeats: "Adjust Seats",
  addSeat: "Add Seat",
  currentAllotment: "Current Allotment",
  viewChart: "View Chart",
  closeChart: "Close Chart",
  dateFrom: "Date From",
  dateTo: "Date To",
  quantityAvailable: "Quantity Available",
  reason: "Reason",
  serviceType: "Service Type",
  allotmentHistory: "Allotment History",
  id: "ID",
  editHistory: "Edit History",
  createdAt: "Created At",
  undo: "Undo",
  allBranches: "All Branches",
  kids: "Kids",
};
