export default {
  header: {
    logout: "ออกจากระบบ",
    language: "เปลี่ยนภาษา",
    searchForm: {
      inputPlaceholder: "หมายเลขการจอง",
      button: "ค้นหา",
    },
    notifications: {
      NewForYou: "ใหม่สำหรับคุณ",
      MarkAllRead: "อ่านทั้งหมด",
      PreviousNotifications: "การแจ้งเตือนก่อนหน้า",
      updateHeadline: "อัปเดต Headline",
      youHave: "คุณมี",
      newPendingBooking: "การจองใหม่ที่รอดำเนินการ",
      newPendingConfirmationBooking: "การจองใหม่ที่รอการยืนยัน",
      seemore: "ดูเพิ่มเติม",
      newCustonerReview: "รีวิวลูกค้าใหม่",
      booking: "การจอง",
      wroteAReview: "เขียนรีวิว",
    },
  },
  navigationSidebar: {
    dashboard: "แดชบอร์ด",
    booking: "การจอง",
    bookingHistory: "ประวัติการจอง",
    voucher: "เวาเชอร์",
    voucherList: "รายการเวาเชอร์",
    voucherHistory: "ประวัติเวาเชอร์",
    createVoucher: "สร้างเวาเชอร์",
    editVoucher: "แก้ไขบัตรกำนัล",
    package: "แพ็กเกจ",
    billing: "การเรียกเก็บเงิน",
    createPackage: "สร้างแพ็กเกจ",
    editPackage: "แก้ไขแพ็กเกจ",
    reviewManangement: "การจัดการรีวิว",
    marketing: "การตลาด",
    accountManagement: "การจัดการบัญชี",
    profile: "โปรไฟล์",
    store: "ร้านค้า",
    user: "สิทธิของผู้ใช้และการเข้าถึง",
    help: "ช่วยเหลือ",
    menu: "เมนู",
    menuOverview: "ภาพรวมเมนู",
    menuItems: "รายการเมนู",
    createMenuItem: "Create Menu Item",
    editProfile: "แก้ไขโปรไฟล์",
    editMenuItem: "Edit Menu Item",
    analytics: "การวิเคราะห์",
    performanceReview: "การประเมินผล",
    ranking: "การจัดอันดับ",
    allotment: "การจัดการที่นั่ง",
    createAllotment: "สร้างจำนวนที่นั่ง",
    editAllotment: "แก้ไขที่นั่ง",
    editHistory: "ประวัติการแก้ไข",
    availableSeats: "ที่นั่งที่ว่าง",
    createBooking: "สร้างการจอง",
    editBooking: "แก้ไขการจอง",
    marketingDetails: "รายละเอียดการตลาด",
    pageNotFound: "ไม่พบหน้านี้",
    exportHistory: "ประวัติการส่งออก",
    report: "รายงาน",
  },
  registerLayout: {
    register: "ลงทะเบียน",
  },
};
