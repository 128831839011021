export const accountManagementRouter = [
  {
    path: "/accounts/profile",
    name: "accountManagement-profile",
    component: () => import("../pages/account-management/profile/Profile.vue"),
    meta: {
      text: "$vuetify.layout.navigationSidebar.profile",
      parent: {
        name: "$vuetify.layout.navigationSidebar.accountManagement",
        disabled: false,
        path: "/accounts",
        exact: true,
      },
    },
  },
  {
    path: "/accounts",
    name: "accountManagement",
    component: () => import("../pages/account-management/user/User.vue"),
    meta: {
      text: "$vuetify.layout.navigationSidebar.user",
      parent: {
        name: "$vuetify.layout.navigationSidebar.accountManagement",
        disabled: false,
        exact: true,
      },
    },
  },
  {
    path: "/accounts/store",
    name: "accountManagement-store",
    component: () => import("../pages/account-management/store/StorePage.vue"),
    meta: {
      text: "$vuetify.layout.navigationSidebar.store",
      parent: {
        name: "$vuetify.layout.navigationSidebar.accountManagement",
        disabled: false,
        path: "/accounts",
        exact: true,
      },
    },
  },
];
