export default {
  title: "Day & Time To Receive Bookings",
  youCannotReduceTheSeatsBelow10Seats:
    "คุณไม่สามารถลดที่นั่งต่ำกว่า 10 ที่นั่งโปรดติดต่อเราผ่านการสนับสนุนและเราจะตอบกลับคุณภายใน 1-2 วันทำการ",
  action: "action",
  adults: "ผู้ใหญ่",
  allotment: "จำนวนที่นั่งเมื่อเวลา",
  allotmentCapacityUtilization: "อัตราส่วนการจองที่นั่งที่เปิดในระบบ",
  allotmentCapacityUtilizationComparison: "เปรียบเทียบอัตราส่วนการจองที่นั่ง",
  allotmentDetails: "รายละเอียดที่นั่ง",
  applyToAllDay: "ใช้กับทุกวัน",
  availableAllotment: "ที่นั่งที่เปิดในระบบ",
  availableSeats: "ที่นั่งที่ว่างอยู่",
  avail: "ยังว่าง",
  block: "บล็อก",
  blocked: "ถูกบล็อก",
  bookedAllotment: "ที่นั่งที่จองแล้ว",
  bookedSeats: "ที่นั่งที่จองแล้ว",
  bookingID: "หมายเลขการจอง",
  cancel: "ยกเลิก",
  close: "ปิด",
  createAllotment: "สร้างจำนวนที่นั่ง",
  customerName: "ชื่อลูกค้า",
  fullday: "ทั้งวัน",
  daily: "รายวัน",
  date: "วันที่",
  dayTimeToReceiveBookings: "วันและเวลาในการรับการจอง",
  dineIn: "ทานที่ร้าน",
  due: "จ่ายที่ร้าน",
  edit: "แก้ไข",
  editAllotment: "แก้ไขที่นั่ง",
  editPackage: "แก้ไขแพ็กเกจ",
  endDate: "วันที่สิ้นสุด",
  endTime: "เวลาสิ้นสุด",
  friday: "วันศุกร์",
  lastMonth: "เดือนที่แล้ว",
  letOpenUpSomeSeats: "เปิดที่นั่งเพิ่มกันเถอะ!",
  monday: "วันจันทร์",
  monthly: "รายเดือน",
  moreDetails: "รายละเอียดเพิ่มเติม",
  other: "อื่น ๆ",
  packageDetails: "รายละเอียดแพ็กเกจ",
  packageName: "ชื่อแพ็กเกจ",
  packageType: "ประเภทแพ็กเกจ",
  paidAmount: "จำนวนเงินที่ชำระแล้ว",
  partySize: "ขนาดของกลุ่ม",
  price: "ราคา",
  saturday: "วันเสาร์",
  seatAvailable: "ที่นั่งที่เหลือ",
  seats: "ที่นั่ง",
  specialRequest: "คำขอพิเศษ",
  startDate: "วันที่เริ่มต้น",
  startTime: "เวลาเริ่มต้น",
  status: "สถานะ",
  submit: "ส่ง",
  sunday: "วันอาทิตย์",
  support: "ฝ่าย Support",
  thisMonth: "เดือนนี้",
  thursday: "วันพฤหัสบดี",
  time: "เวลา",
  today: "วันนี้",
  total: "ทั้งหมด",
  totalAllotment: "ที่นั่งทั้งหมด",
  totalAllotmentSeat: "จำนวนที่นั่งทั้งหมด",
  totalMin: "ทั้งหมด (ต่ำสุด {0})",
  totalSeat: "ที่นั่งทั้งหมด",
  tuesday: "วันอังคาร",
  unblock: "เปิดที่นั่ง",
  wednesday: "วันพุธ",
  successfullyCreatedAllotment: "คุณสร้างที่นั่งสำเร็จ!",
  pleaseSelect: "เลือกอย่างน้อยหนึ่งอย่าง",
  noLongerAvailable: "ไม่พบข้อมูล",
  firstBookingTime: "เวลาแรกที่เปิดให้จอง",
  lastBookingTime: "เวลาสุดท้ายที่เปิดให้จอง",
  textAllotment:
    "คุณไม่สามารถลดที่นั่งให้ต่ำกว่า {0} ได้ โปรดติดต่อ Line ID: @HHforBusiness จากนั้นทางเราจะมีการตอบกลับภายใน 1-2 วันทำการ",
  blockAllotment: "บล็อกที่นั่ง",
  privateFunction: "มีงานเลี้ยงส่วนตัว",
  restaurantIsClosed: "ร้านปิด",
  LowFoodStock: "Low food stock",
  fullyBooked: "Fully Booked",
  Others: "อื่น ๆ",
  booked: "จองแล้ว",
  reasonToBlock: "เหตุผลในการบล็อกที่นั่ง",
  allotmentText:
    "ถ้าคุณต้องการบล็อกที่นั่งล่วงหน้า กรุณาติดต่อฝ่าย support ซึ่งจะใช้เวลา 1-2 วันทำการในการตอบกลับ.",
  hours: "ชั่วโมง",
  minutes: "นาที",
  quantity: "จำนวนที่นั่ง",
  quantityMin: "จำนวนที่นั่ง (ต่ำสุด {0})",
  seatsAdjusted: "จำนวนที่นั่งถูกปรับ",
  adjustSeats: "แก้ไขจำนวนที่นั่ง",
  addSeat: "เพิ่มจำนวนที่นั่ง",
  currentAllotment: "จำนวนที่นั่งปัจจุบัน",
  viewChart: "ดูกราฟ",
  closeChart: "ปิดกราฟ",
  dateFrom: "วันที่เริ่มต้น",
  dateTo: "วันที่สิ้นสุด",
  quantityAvailable: "จำนวนที่นั่งที่เหลือ",
  reason: "เหตุผล",
  serviceType: "ประเภทบริการ",
  allotmentHistory: "ประวัติการจอง",
  id: "ID",
  editHistory: "ประวัติการแก้ไข",
  createdAt: "สร้างเมื่อ",
  undo: "ยกเลิก",
  allBranches: "สาขาทั้งหมด",
  kids: "เด็ก",
};
