export default {
  inputPlaceholder: "Menu list name",
  menuOverview: "Menu Overview",
  menuList: "Menu List",
  menu: "Menu",
  menuItem: "菜单项目",
  menuImage: "Menu Image",
  menuItemText:
    "Upload your food menu by items for easy discovery and menu management in the future.",
  menuImageText:
    "Upload the menu you already have to launch your store faster.",
  total: "Total",
  inactive: "Inactive",
  createMenuItems: "Create Menu Item",
  inputMenuItem: "菜单项名称",
  deleteMenuItems: "Delete All Menu Items",
  editAMenu: "Edit a menu",
  tableHeader: {
    menuId: "无",
    photo: "Photo",
    image: "图片",
    name: "名称",
    description: "描述",
    price: "价格",
    subMenu: "Sub Menu",
    category: "类别",
    action: "操作",
  },
  showOnlyItemsWithoutImage: "仅显示无图片的项目",
  selectMain: "主要语言：{0}",
  selectOptional: "可选语言：{0}",
  removeImage: "移除图片",
  uploadImage: "上传图片",
  areYouSureWantToRemoveThisImage: "您确定要移除此图片吗?",
  remove: "移除",
};
