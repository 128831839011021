export default {
  title: "Day & Time To Receive Bookings",
  youCannotReduceTheSeatsBelow10Seats:
    "您无法将座位减少到低于10。请联系 Line ID: @HHforBusiness, 我们将在1-2个工作日内回复。",
  action: "action",
  adults: "成人",
  allotmentCapacityUtilization: "配额容量利用率",
  allotmentCapacityUtilizationComparison:
    "Allotment Capacity Utilization Comparison",
  allotment: "配额",
  allotmentDetails: "配额详情",
  applyToAllDay: "Apply to All Day",
  availableAllotment: "可用配额",
  availableSeats: "可用座位",
  avail: "可用",
  block: "封锁",
  blocked: "已阻止",
  bookedAllotment: "已预订配额",
  bookedSeats: "已预订座位",
  bookingID: "Booking ID",
  cancel: "Cancel",
  close: "Close",
  createAllotment: "Create Allotment",
  customerName: "Customer Name",
  fullday: "全天",
  daily: "Daily",
  date: "日期",
  dayTimeToReceiveBookings: "Day & Time to receive bookings",
  dineIn: "Dine in",
  due: "Due",
  edit: "編輯",
  editAllotment: "编辑配额",
  editPackage: "Edit Package",
  endDate: "结束日期",
  endTime: "结束时间",
  friday: "Friday",
  lastMonth: "Last Month",
  letOpenUpSomeSeats: "Let’s open up some seats!",
  monday: "Monday",
  monthly: "每月",
  moreDetails: "More Details",
  other: "Other",
  packageDetails: "Package Details",
  packageName: "套餐名称",
  packageType: "Package Type",
  paidAmount: "Paid Amount",
  partySize: "Party size",
  price: "价格",
  saturday: "Saturday",
  seatAvailable: "Seat Available",
  seats: "座位",
  specialRequest: "Special Request",
  startDate: "开始日期",
  startTime: "开始时间",
  status: "status",
  submit: "Submit",
  sunday: "Sunday",
  support: "Support",
  thisMonth: "This Month",
  thursday: "Thursday",
  time: "time",
  today: "Today",
  total: "总计",
  totalAllotment: "总配额",
  totalAllotmentSeat: "总配额",
  totalMin: "Total (Min {0})",
  totalSeat: "Total Seat",
  tuesday: "Tuesday",
  unblock: "取消阻止",
  wednesday: "Wednesday",
  successfullyCreatedAllotment: "You have successfully created the allotment",
  pleaseSelect: "Please Select",
  noLongerAvailable: "No longer available",
  firstBookingTime: "First Booking time",
  lastBookingTime: "Last Booking time",
  textAllotment:
    "您无法将座位减少到低于{0}。请联系 Line ID:@HHforBusiness, 我们将在1-2个工作日内回复。",
  blockAllotment: "配额详情",
  privateFunction: "私人活动",
  restaurantIsClosed: "餐厅关闭",
  LowFoodStock: "食材库存不足",
  fullyBooked: "已订满",
  Others: "其他",
  booked: "已预订",
  reasonToBlock: "封锁原因",
  allotmentText:
    "如果您尝试阻止未来的日期, 请通过支持联系我们, 我们将在1-2个工作日内回复您。",
  hours: "小时",
  minutes: "分钟 ",
  quantity: "数量",
  quantityMin: "数量 (最低 {0})",
  seatsAdjusted: "已调整座位",
  adjustSeats: "调整座位",
  addSeat: "增加座位",
  currentAllotment: "当前配额",
  viewChart: "View Chart",
  closeChart: "Close Chart",
  dateFrom: "Date From",
  dateTo: "Date To",
  quantityAvailable: "可用数量",
  reason: "原因",
  serviceType: "服务类型",
  allotmentHistory: "配额历史",
  id: "ID",
  editHistory: "编辑历史",
  createdAt: "創建於",
  undo: "撤消",
  allBranches: "所有分店",
  kids: "儿童",
};
