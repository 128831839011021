import { defineStore } from "pinia";
import {
  getProfileAccountManagement,
  updateProfileAccountManagement,
  getViewListUser,
  deleteUser,
  getRoleUser,
  createNewUser,
  editUser,
  getViewEditUser,
  resetPasswordUser,
  getListCity,
  getListDistricts,
  getStoreDetail,
  updateStore,
  updateProgress,
  updateDocuments,
  getDocuments,
  reverseGeocode,
  createTemporaryStaff,
} from "@/api/accountManagement";
import { transformToFormDataDynamic, isFile } from "@/utils/global.util";

export const useProfileFormStore = defineStore({
  id: "profile",
  state: () => ({
    type: null,
    storeDetailsData: null,
    sell_type: "package",
    progress: {
      steps_verify_packages: 0,
      steps_verify_vouchers: 0,
    },
    infoForm: {
      // county: null,
      // currency: null,
      name_en: null,
      name_th: null,
      short_name_en: null,
      short_name_th: null,
      primary_phone: null,
      secondary_phone: null,
      opening_hours: null,
      opening_hours_en: null,
      opening_hours_th: null,
      website: null,
      description_en: null,
      description_th: null,
      hotel_information: null,
      experience_information: null,
      branch: null,
    },
    contactPerson: {
      your_name: null,
      your_phone: null,
      your_role: null,
      your_email: null,
    },
    photoManagement: {
      logo: null,
      cover: null,
      thumbnails: [],
    },
    cuisineForm: {
      old_primary_id: null,
      primary_id: null,
      cuisine_ids: [],
    },
    diningStyleForm: {
      old_primary_dining_style: [],
      primary_dining_style: null,
      dining_style_ids: [],
    },
    locationForm: {
      location_in_gg: "",
      address: "",
      lat: 0,
      lng: 0,
      city_id: 0,
      district_id: 0,
      popular_zone_ids: [],
      shopping_mall_id: 0,
      bts_route_id: [],
      mrt_route_id: [],
      location_ids: [],
      primary_location_id: null,
    },
    facilitiesForm: {
      facility_ids: [],
    },
    location: {
      listCity: [],
      listDistrict: [],
    },
    profileForm: {
      avatar: null,
      firstName: null,
      email: null,
      role: null,
      lastName: null,
      oldPassword: "",
      newPassword: "",
      status: null,
      joined: null,
      lastSeen: null,
      platform: null,
      convertJoined: null,
      calculateDate: null,
      phone: {
        countryCode: null,
        phoneNumber: null,
      },
      contactNumber: null,
      data: [],
      pagination: {},
    },
    listRoleUser: [],
    defineListRole: [],
    documents_steps: {
      type_of_enterprise: null,
      documents: {
        certificate_pp20: {
          file: null,
          id: null,
        },
        certificate_dbd: {
          file: null,
          id: null,
        },
        identification_card: {
          file: null,
          id: null,
        },
        bankbook: {
          file: null,
          id: null,
        },
        e_contract: {
          file: null,
          id: null,
        },
      },
    },
    isFetching: false,
  }),
  getters: {
    currencyCode: (state) => state.storeDetailsData?.country_code || null,
    getViewProfileAccountManagement: (state) => state.profileForm,
    inventorySource: (state) =>
      state.storeDetailsData?.inventory_source || null,
  },
  actions: {
    getProfileAccountManagement() {
      return new Promise((resolve, reject) => {
        getProfileAccountManagement()
          .then((response) => {
            const profileDetails = response.data.attributes;
            this.profileForm.firstName = profileDetails.first_name;
            // console.log('aaaa',this.firstName);
            this.profileForm.lastName = profileDetails.last_name;
            this.profileForm.email = profileDetails.email;
            this.profileForm.role = profileDetails.role;
            this.profileForm.status = profileDetails.status;
            this.profileForm.joined = profileDetails.created_at;
            this.profileForm.lastSeen = profileDetails.last_sign_in_at;
            this.profileForm.platform = profileDetails.last_platform_stay_in;
            this.profileForm.phone.countryCode =
              profileDetails.phone.country_code;
            this.profileForm.phone.phoneNumber =
              profileDetails.phone.phone_number;
            this.profileForm.avatar = profileDetails.avatar_url;
            // console.log(`profileDetails`, this.platform);
            this.profileForm.contactNumber =
              profileDetails.phone.country_code +
              profileDetails.phone.phone_number;
            resolve(true);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    handleEditProfile(payload) {
      return new Promise((resolve, reject) => {
        updateProfileAccountManagement(payload)
          .then((response) => {
            resolve(response);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    getViewListUser(payload) {
      return new Promise((resolve, reject) => {
        getViewListUser(payload)
          .then((response) => {
            const fmt2 = new Intl.DateTimeFormat("en-GB", {
              year: "numeric",
              day: "numeric",
              month: "short",
              hour: "numeric",
              minute: "numeric",
            });
            // console.log('ress',response);
            const dataFormat = response.data.map((item) => {
              const { attributes, id, type } = item;
              const dataItem = {
                id: id,
                type: type,
                userId: id,
                name: attributes.name,
                email: attributes.email,
                role: attributes.role,
                createdAt: fmt2.format(new Date(attributes.created_at)),
                lastOnline: attributes.last_sign_in_at,
                expiredAt: `Valid until ${fmt2.format(
                  new Date(attributes.expired_at),
                )}`,
              };
              return dataItem;
            });
            this.data = dataFormat;
            this.pagination = response.meta;
            // console.log("pagination", payload);
            resolve(true);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    deleteUser(payload) {
      return new Promise((resolve, reject) => {
        deleteUser(payload)
          .then((response) => {
            resolve(response);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    getRoleUser() {
      return new Promise((resolve, reject) => {
        getRoleUser()
          .then((response) => {
            this.listRoleUser = response.roles;
            this.defineListRole = this.listRoleUser.map((role) => {
              return {
                value: role,
                label: role,
              };
            });

            // this.defineListRole = Object.assign({}, this.listRoleUser);
            // console.log('definelistrole',this.defineListRole);
            // const abc =

            // const ahh = map(this.listRoleUser, (role) => {
            //   return {
            //     value: role.length,
            //     label: role.length,
            //   };
            // });
            // console.log('ahh',ahh)

            // const groupedRoles = groupBy(response.roles, (roles) => roles);

            // this.packageTypes = map(groupedRoles, (pack) => {
            //   return {
            //     value: pack[0].type_short,
            //     label: pack[0].type,
            //   };
            // });

            resolve(true);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    createNewUser(payload) {
      return new Promise((resolve, reject) => {
        createNewUser(payload)
          .then((response) => {
            resolve(response);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    editUser(id, payload) {
      return new Promise((resolve, reject) => {
        editUser(id, payload)
          .then((response) => {
            resolve(response);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getViewEditUser(id) {
      return new Promise((resolve, reject) => {
        getViewEditUser(id)
          .then((response) => {
            // const viewUser = response.data.attributes;
            resolve(response.data?.attributes);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    resetPasswordUser(payload) {
      return new Promise((resolve, reject) => {
        resetPasswordUser(payload)
          .then((response) => {
            resolve(response);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getListCity(payload) {
      return new Promise((resolve, reject) => {
        getListCity(payload)
          .then((response) => {
            const dataFormat = response.data.map((item) => {
              const { attributes } = item;
              const dataItem = {
                id: attributes.id,
                name: attributes.name,
              };
              return dataItem;
            });
            this.location.listCity = dataFormat;
            resolve(true);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getListDistricts(payload) {
      return new Promise((resolve, reject) => {
        getListDistricts(payload)
          .then((response) => {
            const dataFormat = response.data.map((item) => {
              const { attributes } = item;
              const dataItem = {
                id: attributes.id,
                name: attributes.name,
              };
              return dataItem;
            });
            this.location.listDistrict = dataFormat;
            resolve(true);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    // getLogoDetail() {
    //   return new Promise((resolve, reject) => {
    //     getStoreDetail()
    //       .then((response) => {
    //         const { attributes } = response.data;
    //         this.storeDetailsData = attributes;
    //         resolve(true);
    //       })
    //       .catch((err) => {
    //         reject(err);
    //       });
    //   });
    // },
    getStoreDetail(payload) {
      return new Promise((resolve, reject) => {
        getStoreDetail(payload)
          .then((response) => {
            const { attributes } = response.data;
            this.storeDetailsData = attributes;

            const assignProgressSteps = (attributeName, defaultValue = 0) => {
              return attributes[attributeName] || defaultValue;
            };
            this.progress.steps_verify_packages = assignProgressSteps(
              "steps_verify_packages",
            );
            this.progress.steps_verify_vouchers = assignProgressSteps(
              "steps_verify_vouchers",
            );

            this.type = attributes.outlet_type;

            // info form
            this.infoForm.name_en = attributes.name_en;
            this.infoForm.name_th = attributes.name_th;
            this.infoForm.short_name_en = attributes.short_name_en;
            this.infoForm.short_name_th = attributes.short_name_th;
            this.infoForm.description_en = attributes.misc_en;
            this.infoForm.description_th = attributes.misc_th;

            const locale = window.localStorage.getItem("locale");
            const getOpeningHours = (locale, attributes) => {
              return locale === "en"
                ? attributes.opening_hours_en
                : attributes.opening_hours_th;
            };
            this.infoForm.opening_hours = getOpeningHours(locale, attributes);

            this.infoForm.opening_hours_en = attributes.opening_hours_en;
            this.infoForm.opening_hours_th = attributes.opening_hours_th;
            this.infoForm.website = attributes.website;

            this.photoManagement.logo = attributes.logo_url;
            this.photoManagement.cover = attributes.image_cover_url;
            this.photoManagement.thumbnails = attributes.thumbnails;

            const formatPhoneNumber = (phone) => {
              if (!phone) return null;
              return phone.slice(0, 3).trim() === "+66"
                ? phone.slice(3).trim()
                : phone.trim();
            };
            this.infoForm.primary_phone = formatPhoneNumber(attributes.phone);
            this.infoForm.secondary_phone = formatPhoneNumber(
              attributes.secondary_phone,
            );

            this.infoForm.hotel_information = attributes.hotel_information;
            this.infoForm.experience_information =
              attributes.experience_information;

            const getContactPersonAttribute = (attribute) => {
              return attribute || null;
            };
            // contact person
            this.contactPerson.your_email = getContactPersonAttribute(
              attributes.contact_person?.email,
            );
            this.contactPerson.your_name = getContactPersonAttribute(
              attributes.contact_person?.name,
            );
            this.contactPerson.your_phone = formatPhoneNumber(
              attributes.contact_person?.phone,
            );

            this.contactPerson.your_role =
              attributes.contact_person?.role || null;

            const extractIds = (tags) => {
              return tags?.map((item) => item.id) || [];
            };
            const updateFilterTagIdsWithPrimary = (filterTagIds, primaryId) => {
              if (primaryId && !filterTagIds.includes(primaryId)) {
                filterTagIds.push(primaryId);
              }
              return filterTagIds;
            };

            // cuisine form
            this.cuisineForm.cuisine_ids = extractIds(attributes.cuisines);
            this.cuisineForm.primary_id = attributes.primary_cuisine?.id;
            this.cuisineForm.old_primary_id = attributes.primary_cuisine?.id;
            this.cuisineForm.cuisine_ids = updateFilterTagIdsWithPrimary(
              this.cuisineForm.cuisine_ids,
              this.cuisineForm.primary_id,
            );

            // dining style form
            this.diningStyleForm.dining_style_ids = extractIds(
              attributes.dining_styles,
            );
            this.diningStyleForm.primary_dining_style =
              attributes.primary_dining_style?.id;
            this.diningStyleForm.old_primary_dining_style =
              attributes.primary_dining_style?.id;
            this.diningStyleForm.dining_style_ids =
              updateFilterTagIdsWithPrimary(
                this.diningStyleForm.dining_style_ids,
                this.diningStyleForm.primary_dining_style,
              );

            // location form
            this.locationForm.city_id = attributes.location?.city_id;
            this.locationForm.district_id = attributes.location?.district_id;
            this.locationForm.location_in_gg =
              attributes.location?.google_map_link;

            const parseCoordinate = (coordinate) => {
              return parseFloat(coordinate || 0);
            };
            this.locationForm.lat = parseCoordinate(attributes.lat);
            this.locationForm.lng = parseCoordinate(attributes.lng);

            this.locationForm.address = attributes.location?.address;
            this.locationForm.popular_zone_ids = extractIds(
              attributes.popular_zones,
            );
            this.locationForm.location_ids = extractIds(attributes.locations);

            const extractLocationId = (attribute, defaultValue = null) => {
              return attribute?.id || attribute?.[0]?.id || defaultValue;
            };
            this.locationForm.primary_location_id = extractLocationId(
              attributes.primary_location,
            );
            this.locationForm.bts_route_id = extractLocationId(
              attributes.bts_routes,
            );
            this.locationForm.mrt_route_id = extractLocationId(
              attributes.mrt_routes,
            );
            this.locationForm.shopping_mall_id = extractLocationId(
              attributes.shopping_malls,
            );

            // facilities form
            this.facilitiesForm.facility_ids = extractIds(
              attributes.facilities,
            );

            resolve(true);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    updateStore(payload) {
      return new Promise((resolve, reject) => {
        updateStore(payload)
          .then((response) => {
            if (response?.success === false) {
              reject(response);
              return;
            }
            resolve(true);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    addThumbnailItem() {
      this.photoManagement.thumbnails.push({
        id: new Date().getTime(),
        url: null,
        priority: null,
      });
    },
    deleteThumbnailItem(id) {
      this.photoManagement.thumbnails = this.photoManagement.thumbnails.filter(
        (item) => item.id != id,
      );
    },
    updateDocuments({ restaurant_id }) {
      const formData = new FormData();
      const { type_of_enterprise, documents } = this.documents_steps;
      const documentsData =
        Object.entries(documents).map((value) => {
          if (!value[1].file && !value[1].id) return null;

          if (!isFile(value[1].file) && value[1].id) {
            if (value[1].is_destroy) {
              return {
                id: value[1].id,
                is_destroy: true,
              };
            }
            return {
              id: value[1].id,
            };
          }

          let isDestroy = null;
          if (isFile(value[1].file)) {
            isDestroy = false;
          } else if (value[1].is_destroy) {
            isDestroy = true;
          }
          return {
            id: value[1].id || null,
            file: value[1].file || null,
            name: value[0] || null,
            is_destroy: isDestroy,
          };
        }) || [];

      const payload = {
        type_of_enterprise: type_of_enterprise,
        documents: transformToFormDataDynamic(
          documentsData,
          formData,
          "documents",
        ),
        restaurant_id,
      };

      const postData = transformToFormDataDynamic(payload, formData, null);
      return new Promise((resolve, reject) => {
        updateDocuments(postData)
          .then((response) => {
            if (response?.success === false) {
              reject(response);
              return;
            }
            resolve(true);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    resetDocument() {
      this.documents_steps = {
        type_of_enterprise: null,
        documents: {
          certificate_pp20: {
            file: null,
            id: null,
          },
          certificate_dbd: {
            file: null,
            id: null,
          },
          identification_card: {
            file: null,
            id: null,
          },
          bankbook: {
            file: null,
            id: null,
          },
          e_contract: {
            file: null,
            id: null,
          },
        },
      };
    },
    getDocuments(payload) {
      this.resetDocument();
      return new Promise((resolve, reject) => {
        getDocuments(payload)
          .then((response) => {
            if (response?.success === false) {
              reject(response);
              return;
            }

            if (response?.data.length) {
              const docsData = response.data?.reduce((groups, item) => {
                if (!groups[item.attributes.name]) {
                  groups[item.attributes.name] = {};
                }
                groups[item.attributes.name] = {
                  id: item.id,
                  file: item.attributes.file,
                  is_destroy: false,
                };

                return groups;
              }, {});
              this.documents_steps.type_of_enterprise =
                response?.enterprise_type || null;
              this.documents_steps.documents = {
                ...this.documents_steps.documents,
                ...docsData,
              };
            }
            resolve(true);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    updateProgress({ restaurant_id }) {
      const payload = {
        steps_verify: {
          packages: this.progress.steps_verify_packages,
          vouchers: this.progress.steps_verify_vouchers,
        },
        restaurant_id,
      };
      return new Promise((resolve, reject) => {
        updateProgress(payload)
          .then((response) => {
            if (response?.success === false) {
              reject(response);
              return;
            }
            resolve(true);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getAddressFromLatLng(payload) {
      return new Promise((resolve, reject) => {
        reverseGeocode(payload)
          .then((response) => {
            resolve(response);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    createTemporaryStaff(restaurantId) {
      return new Promise((resolve, reject) => {
        createTemporaryStaff(restaurantId)
          .then((response) => {
            if (response?.success === false) {
              reject(response);
              return;
            }
            resolve(response);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },
});
