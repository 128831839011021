import Vue from "vue";
import VueRouter from "vue-router";
import { registerRouter } from "./register.route";
import { packageRouter } from "./package.route";
import { voucherRouter } from "./voucher.route";
import { allotmentRouter } from "./allotment.route";
import { accountManagementRouter } from "./accountManagement.route";
import { analyticsRouter } from "./analytics.router";
import { menuRouter } from "./menu.route";
import { menuV2Router } from "./menuv2.route";

const DefaultLayout = () => import("@/layouts/default/DefaultLayout.vue");

/**
 * No stacktrace on NavigationDuplicated error uncaught in promise
 * https://github.com/vuejs/vue-router/issues/2881
 *
 **/
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch((err) => {
    if (VueRouter.isNavigationFailure(err)) {
      return err;
    }

    return Promise.reject(err);
  });
};

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: import.meta.env.BASE_URL,
  routes: [
    {
      path: "/login",
      name: "login",
      component: () => import("../pages/login/LoginPage.vue"),
      meta: {
        text: "$vuetify.login.login",
      },
    },
    {
      path: "/auto-login",
      name: "auto-login",
      component: () => import("../pages/login/AutoLogin.vue"),
      meta: {
        text: "$vuetify.login.login",
      },
    },
    {
      path: "/print-menu",
      name: "print-menu",
      component: () => import("../pages/print/PrintMenu.vue"),
      meta: {
        text: "$vuetify.menu.menuOverview",
      },
    },
    ...registerRouter,
    ...allotmentRouter,
    ...menuRouter,
    ...menuV2Router,
    {
      path: "/global/helps",
      component: () => import("@/layouts/registration/RegistrationLayout.vue"),
      meta: {
        text: "$vuetify.layout.navigationSidebar.help",
      },
      children: [
        {
          path: "",
          component: () => import("../pages/help/HelpPage.vue"),
          meta: {
            text: "$vuetify.layout.navigationSidebar.help",
            style: "custom-page-style",
          },
        },
      ],
    },
    {
      path: "/",
      component: DefaultLayout,
      meta: {
        text: "$vuetify.layout.navigationSidebar.marketing",
      },
      children: [
        {
          path: "/",
          name: "Dashboard",
          component: () => import("../pages/dashboard/DashboardPage.vue"),
          meta: {
            text: "$vuetify.layout.navigationSidebar.dashboard",
            defaultVisited: true,
          },
        },
        {
          path: "/booking",
          name: "booking",
          component: () => import("../pages/booking/BookingList.vue"),
          meta: {
            text: "$vuetify.layout.navigationSidebar.booking",
          },
        },
        {
          path: "/booking/history/:id",
          name: "booking-history",
          component: () => import("../pages/booking/BookingList.vue"),
          meta: {
            text: "$vuetify.layout.navigationSidebar.bookingHistory",
            parent: {
              name: "Booking",
              disabled: false,
              path: "/booking",
              exact: true,
            },
          },
        },
        {
          path: "booking/create",
          name: "createbooking",
          component: () =>
            import("../pages/booking/create-booking/CreateBooking.vue"),
          meta: {
            text: "$vuetify.layout.navigationSidebar.createBooking",
            parent: {
              name: "$vuetify.layout.navigationSidebar.booking",
              disabled: false,
              path: "/booking",
              exact: true,
            },
          },
        },
        {
          path: "booking/edit/:id",
          name: "editbooking",
          component: () =>
            import("../pages/booking/edit-booking/EditBooking.vue"),
          meta: {
            text: "$vuetify.layout.navigationSidebar.editBooking",
            parent: {
              name: "$vuetify.layout.navigationSidebar.booking",
              disabled: false,
              path: "/booking",
              exact: true,
            },
          },
        },
        ...voucherRouter,
        ...packageRouter,
        {
          path: "/billing",
          name: "billing",
          component: () => import("../pages/billing/BillingPage.vue"),
          meta: {
            text: "$vuetify.layout.navigationSidebar.billing",
          },
        },
        ...analyticsRouter,
        ...accountManagementRouter,
        {
          path: "/help",
          name: "help",
          component: () => import("../pages/help/HelpPage.vue"),
          meta: {
            text: "$vuetify.layout.navigationSidebar.help",
          },
        },
        {
          path: "/review/:id?",
          name: "Review Management",
          component: () =>
            import("../pages/review-manangement/ReviewManangement.vue"),
          meta: {
            text: "$vuetify.layout.navigationSidebar.reviewManangement",
          },
        },
        {
          path: "/marketing",
          name: "marketing",
          component: () => import("../pages/marketing/MarketingPage.vue"),
          meta: {
            text: "$vuetify.marketing.marketing",
          },
        },
        {
          path: "/marketing/:id",
          name: "marketing-details",
          component: () =>
            import("../pages/marketing/details/MarketingDetailsPage.vue"),
          meta: {
            text: "$vuetify.layout.navigationSidebar.marketingDetails",
            parent: {
              name: "$vuetify.layout.navigationSidebar.marketing",
              disabled: false,
              path: "/marketing",
              exact: true,
            },
          },
        },
        {
          path: "/report",
          name: "report",
          component: () => import("../pages/report/ReportPage.vue"),
          meta: {
            text: "$vuetify.layout.navigationSidebar.report",
          },
        },
        {
          path: "/alert",
          name: "alert",
          component: () => import("../pages/AlertTest.vue"),
          meta: {
            text: "Alert For Test",
          },
        },
        {
          path: "/export",
          name: "Export History",
          component: () =>
            import("../pages/export-history/ExportHistoryPage.vue"),
          meta: {
            text: "$vuetify.layout.navigationSidebar.exportHistory",
          },
        },
        {
          path: "/allotment-history",
          name: "allotment-history",
          component: () => import("../pages/allotment/AllotmentHistory.vue"),
          meta: {
            text: "$vuetify.layout.navigationSidebar.editHistory",
            parent: {
              name: "$vuetify.layout.navigationSidebar.allotment",
            },
          },
        },
        {
          path: ":pathMatch(.*)*",
          name: "not-found",
          component: () => import("../pages/404.vue"),
          meta: {
            text: "$vuetify.layout.navigationSidebar.pageNotFound",
          },
        },
      ],
    },
  ],
});

export default router;
