export default {
  bookingList: "预订列表",
  bookingHistory: "预订历史记录",
  buttonStatus: "预订状态",
  buttonEdit: "编辑",
  buttonOther: "其他 ",
  bookingID: "预订编号",
  date: "日期",
  time: "時間",
  customerName: "顧客姓名",
  packageType: "包裝類型",
  packageDetails: "套餐詳情",
  discountDetails: "折扣详情",
  partySize: "派对规模",
  paidAmount: "已付金额",
  specialRequest: "特别要求",
  action: "操作",
  orderStatus: "Order Status:",
  orderLater: "Order Later",
  buttonMenu: "Menu",
  seemore: "查看更多",
  seeless: "收起",
  buttonView: "View",
  moreDetail: "更多详情",
  occasionTag: "Occasion tags",
  driverDetails: "Driver Details",
  noteToDriver: "Note To Driver",
  googleMapLink: "Google Map Link",
  address: "Address",
  est: "Est",
  due: "到期",
  distance: "Distance",
  deliveryfee: "Delivery Fee",
  selfPickup: "Self Pickup",
  delivery: "Delivery",
  noShow: "未出现",
  times: "Times",
  markAsArrived: "mark as arrived",
  cancel: "取消",
  approve: "批准",
  reject: "拒絕",
  sendConfirmationToUser: "发送确认给用户",
  sendCustomSMS: "发送自定义短信",
  Sendreviewlink: "Send review link",
  createABooking: "创建预订",
  bookingDetails: "预订详情",
  tableName: "Table Name",
  tableNumber: "桌号",
  status: "狀態",
  name: "姓名",
  phone: "电话号码",
  adult: "成人",
  kids: "儿童",
  email: "电子邮件",
  notes: "备注",
  paymentDetails: "付款详情",
  paymentStatus: "付款状态",
  dueAmount: "到期金额",
  thb: "THB",
  packageName: "套餐名称",
  totalPackagePrice: "套餐总价",
  depositAmount: "Deposit amount",
  paymentMethods: "付款方式",
  promptPayQR: "PromptPay 二维码",
  debitCreditCard: "借记卡/信用卡",
  shopeePay: "Shopee Pay",
  trueMoneyWallet: "True Money Wallet",
  discountType: "折扣类型",
  totalPriceAfterDiscount: "折扣后总价",
  amount: "金额",
  editBooking: "编辑预订",
  seeReason: "查看原因",
  addMorePacks: "添加更多套餐",
  filters: "搜索筛选",
  bookingStatus: "预订状态",
  customerPhone: "顾客电话 ",
  startTime: "开始时间",
  // partySize: "Party Size",
  serviceType: "服务类型",
  specificPackage: "特定套餐",
  searchFilters: "搜索筛选",
  rangeDate: "日期范围",
  singleDate: "单一日期",
  startDate: "开始日期",
  endDate: "结束日期",
  clearAll: "清除所有",
  showResults: "显示结果",
  all: "所有",
  pendingArrival: "待到达",
  arrived: "已到达",
  cancelled: "已取消",
  hhPendingArrival: "H@H Pending Arrival",
  deliveryCompleted: "Delivery Completed",
  orderReceived: "訂單已接收",
  allYouCanEat: "自助餐 (AYCE)",
  partyPack: "派对套餐 (PP)",
  hh: "H@H (HH)",
  xperience: "体验套餐 (XP)",
  dineIn: "堂食",
  selfPickUp: "Self-Pick up",
  noDeposit: "无押金",
  hasDeposit: "有押金",
  prepaid: "已预付",
  export: "匯出",
  exportBookingList: "导出预订列表",
  createBooking: "创建预订",
  allBookings: "所有预订",
  pendingBookings: "待处理预订",
  todayBookings: "今日预订",
  arrive: "Arrive",
  pleaseSelect: "请选择",
  customSMS: "Custom SMS (160 characters)",
  booking: "預訂",
  discount: "折扣",
  bookingDeatails: "预订详情",
  createdAt: "創建於",
  createBy: "由...製作",
  cancelledBy: "被...取消",
  modifiedBy: "被...修改",
  customerDetails: "顾客详情",
  phoneNo: "电话号码",
  myRestaurantBooking: "餐廳預訂",
  allRestaurantsBooking: "所有餐廳預訂",
  paymentSummary: "付款摘要",
  total: "總",
  method: "方法",
  // deliveryFee: "Delivery Fee",
  driverId: "Driver ID",
  driverName: "Driver name",
  driverPhone: "Driver phone",
  drivingToRestaurant: "Driving  to restaurant",
  trackingLink: "Tracking Link",
  selectDate: "Select Date",
  voucher: "優惠券",
  pleaseChoose: "Please choose a package",
  show: "显示",
  approveThisBooking: "Approve this booking",
  rejectThisBooking: "Reject this booking",
  back: "Back",
  areYouSureApproveThisId:
    "Are you sure you want to approve this booking ID '{0}' ?",
  areYouSureRejectThisId:
    "Are you sure you want to reject this booking ID '{0}' ?",
  alertPendingBooking: `提醒！您有 {0} 筆待處理的預訂！請盡快“批准”或“拒絕”它們。`,
  noResultFound: "未找到结果",
  sorryWeCouldntFindIt: "抱歉，我们未能找到相关内容。",
  tryClearingTheFiltersToDiscoverMoreResults:
    "请尝试清除筛选条件以发现更多结果。",
  clearAllFilters: "清除所有筛选",
  orderCompleted: "訂單已完成",
  arrivedAt: "到达时间",
  pleaseMatchPackageQty: "请确保套餐数量与您的聚会人数匹配.",
  addOn: "加購套餐",
  addOnName: "加購套餐名称",
  pleaseChooseAnAddOn: "请选择加购套餐",
  addAddOn: "添加加购套餐",
  addMoreAddOn: "添加更多加购套餐",
};
