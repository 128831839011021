const DefaultLayout = () => import("@/layouts/default/DefaultLayout.vue");

export const menuRouter = [
  {
    path: "/menus",
    component: DefaultLayout,
    children: [
      {
        path: "",
        name: "menu",
        component: () => import("../pages/menu/MenuList.vue"),
        meta: {
          text: "$vuetify.layout.navigationSidebar.menuOverview",
          parent: {
            name: "$vuetify.layout.navigationSidebar.menu",
            path: "/menus",
            disabled: false,
          },
        },
      },
      {
        path: "items",
        name: "menu-items",
        component: () => import("../pages/menu/menu-items/MenuItems.vue"),
        meta: {
          text: "$vuetify.layout.navigationSidebar.menuItems",
          parent: {
            name: "$vuetify.layout.navigationSidebar.menu",
            path: "/menus",
            disabled: false,
            exact: true,
          },
        },
      },
      {
        path: "/menus/items/edit/:id",
        name: "Edit Menu Item",
        component: () =>
          import("../pages/menu/menu-items/edit/MenuItemEdit.vue"),
        meta: {
          text: "$vuetify.layout.navigationSidebar.editMenuItem",
          parent: {
            name: "$vuetify.layout.navigationSidebar.menu",
            disabled: false,
            path: "/menus/items",
            meta: {
              text: "$vuetify.layout.navigationSidebar.menuItems",
              exact: true,
              parent: {
                name: "$vuetify.layout.navigationSidebar.menu",
                disabled: false,
                exact: false,
                path: "/menus",
              },
            },
          },
        },
      },
    ],
  },
  {
    path: "/menus/items",
    component: DefaultLayout,
    children: [
      {
        path: "create",
        name: "create-menu-item",
        component: () =>
          import("../pages/menu/menu-items/create/MenuItemCreate.vue"),
        meta: {
          text: "$vuetify.layout.navigationSidebar.createMenuItem",
          miniSidebar: true,
          parent: {
            name: "$vuetify.layout.navigationSidebar.menu",
            disabled: false,
            path: "/menus/items",
            meta: {
              text: "$vuetify.layout.navigationSidebar.menuItems",
              exact: true,
              parent: {
                name: "$vuetify.layout.navigationSidebar.menu",
                disabled: false,
                path: "/menus",
                exact: true,
              },
            },
          },
        },
      },
    ],
  },
];
